* {
  margin: 0;
}
html {
  background-color: #383f4d;
}
body {
  background-color: #383f4d;
}
.navroutes {
  position: relative;
}
.navbar {
  position: absolute;
  z-index: 2;
  width: 100%;
}
.routes {
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: "ubuntu";
  src: url("./Assets/Ubuntu/Ubuntu-Regular.ttf");
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span {
  font-family: ubuntu;
}
.tagcloud {
  color: rgb(226, 221, 221);
}
/* .form-label {
  font-size: 1.4rem !important;
  color: #7d7f80;
}
.form-control {
  font-size: 1.4rem !important;
} */
.gfNMGX {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
