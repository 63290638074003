.aboutuscontentmaindiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9;

  /* border-radius: 8px; */
  background: #383f4d;
  opacity: 0.9;
}

.aboutuscontentmaindiv2 {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  width: 90%;
  gap: 20px;
  box-shadow: 12px 12px 24px #232730, -12px -12px 24px #4d576a;
  margin: 10px;
  padding: 10px;
}
.aboutuscontentimage img {
  width: 500px;
}
.aboutuscontentdiv p {
  color: rgb(226, 221, 221);
  font-size: 1.3rem;
}
.aboutuscontentdiv2 {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.aboutuscontentdiv2 li {
  color: rgb(226, 221, 221);
  font-size: 1.3rem;
}
.aboutuscontentimage img {
  animation: float 3s ease-out infinite;
}
@keyframes float {
  50% {
    transform: translate(0, 15px);
  }
}
.aboutuscontentdiv span {
  color: #4b9741;
}
.aboutuscontentdiv2 span {
  color: #4b9741;
}
@media screen and (max-width: 1106px) {
  .aboutuscontentimage img {
    width: 400px;
  }
}
@media screen and (max-width: 920px) {
  .aboutuscontentimage img {
    width: 400px;
  }
  .aboutuscontentmaindiv2 {
    display: flex;
    flex-direction: column;
  }
}
