.skills {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  flex-direction: column;
  margin-top: 50px;
}
.skillsdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  gap: 40px;
}
.skillsNeo {
  border-radius: 18px;
  background: #383f4d;
  box-shadow: 5px 5px 10px green, -5px -5px 10px #535e73;
  padding: 50px;
}
.skillsNeodiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.skillsNeoimage img {
  width: 100px;
}
.skillsNeoname p {
  font-size: 1rem;
  color: rgb(226, 221, 221);
}
.skillsh2 {
  padding: 20px;
}
.skillsh2 h2 {
  font-size: 3rem;
  color: rgb(226, 221, 221);
}
@media screen and (max-width: 500px) {
  .skillsdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
