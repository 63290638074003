.container {
  position: relative;
  /* height: 100vh; */
}
.navbr {
  position: absolute;
  width: 100%;
}
.homepagemaindiv {
  width: 100%;
  height: 100vh;
  background-color: #383f4d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navlinks {
  background-color: #383f4d;
}
.homepagemaindiv2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 10px;
}
.homepage {
  /* background: linear-gradient(to right, #03e9f4, #40873f);
  -webkit-background-clip: text;
  -webkit-text-fill-cover: transparent; */
}

.homepagespn span {
  font-size: 10rem;
  -webkit-text-stroke: 1px #fff;
  color: transparent;
  background-image: linear-gradient(#fff, #fff);
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-position: -810px 0;
  animation: backcolor 5s linear infinite alternate;
}

@keyframes backcolor {
  100% {
    background-position: 0 0;
  }
}

.homepage span {
  font-size: 10vh;
  display: inline-block;
  color: rgb(226, 221, 221);
}
.headSpanNav {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.spanp {
  color: #40873f;
  font-size: 6vw;
}
.headandspan {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.tagImageDiv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageDiv {
  position: absolute;

  top: 50%;
  z-index: 20;
  transform: translate(0, -50%);
}
.imageDiv img {
  width: 300px;
}
.navlinks {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.navlinks a {
  color: #fff;
  text-decoration: none;
  font-size: 2vw;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.float:hover .my-float:hover {
  color: #ee0c0d;
}

.my-float {
  margin-top: 16px;
}
.imageDiv img {
  animation: float 3s ease-out infinite;
}

@keyframes float {
  50% {
    transform: translate(0, 15px);
  }
}

.navlinka a {
  position: relative;
  display: inline-block;
  padding: 25px 30px;
  margin: 40px 0;
  color: #03e9f4;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  margin-right: 50px;
}
.navlinka a:hover {
  background: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 200px #03e9f4;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}
.navlinka a:nth-child(1) {
  filter: hue-rotate(270deg);
}
.navlinka a:nth-child(2) {
  filter: hue-rotate(110deg);
}

.navlinka a span {
  position: absolute;
  display: block;
}
.navlinka a span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: animate1 1s linear infinite;
}
@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
.navlinka a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: animate2 1s linear infinite;
  animation-delay: 0.25s;
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
.navlinka a span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: animate3 1s linear infinite;
  animation-delay: 0.5s;
}
@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}
.navlinka a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: animate4 1s linear infinite;
  animation-delay: 0.75s;
}
@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.btn {
  display: none !important;
}

@media screen and (max-width: 1193px) {
  .homepage span {
    font-size: 8vh;
    display: inline-block;
    color: rgb(226, 221, 221);
  }
  .imageDiv img {
    width: 250px;
  }
}

@media screen and (max-width: 1090px) {
  .homepage span {
    font-size: 6vh;
    display: inline-block;
    color: rgb(226, 221, 221);
  }
}

@media screen and (max-width: 980px) {
  .homepage span {
    font-size: 5vh;
    display: inline-block;
    color: rgb(226, 221, 221);
  }
}
@media screen and (max-width: 980px) {
  .headandspan {
    display: flex;
    flex-direction: column-reverse;
  }
  .homepage span {
    font-size: 6vh;
    display: inline-block;
    color: rgb(226, 221, 221);
  }
  .homepagemaindiv2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .homepagemaindiv {
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  .navlinks {
    display: none;
  }
  .tagImageDiv span {
    display: none;
  }
  .imageDiv {
    position: relative;

    top: 0%;
    z-index: 20;
    transform: translate(0, 0%);
  }
  .navlinka {
    display: none;
  }
  .btn {
    background-image: linear-gradient(to right, #00ccff, #40873f);
    font-size: 0.7rem;
    display: flex !important;
    width: 200px;

    margin-top: 20px !important;
  }

  .btn {
  }
}
@media screen and (max-width: 768px) {
  .homepage span {
    font-size: 1.4rem;
  }
}
