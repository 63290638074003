.staticprojectsdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  z-index: 9;
  flex-direction: column;
  position: relative;
}
.staticprojectsdiv2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
}
.staticprojects {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 5px 2px #61b048;
  border-radius: 20px;
}
.staticprimage img {
  width: 500px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.staticpp p {
  font-size: 1.4rem;
  padding: 5px;
  font-weight: 600;
  color: #fff;
}
.staticprojectsdiv2 a {
  text-decoration: none;
}
.statich33 h2 {
  font-size: 3rem;
  color: rgb(226, 221, 221);
  margin-bottom: 50px;
}
@media screen and (max-width: 600px) {
  .staticprimage img {
    width: 300px;
  }
}
