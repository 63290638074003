.projectsdiv {
  /* background-color: rgb(60, 59, 59); */
  /* position: absolute; */
  width: 100%;
}
.container {
  position: relative;
}
.navbr {
  /* background-color: rgb(60, 59, 59); */
  z-index: 9999;
  position: absolute;
  width: 100%;
  cursor: pointer;
  /* background-color: #383f4d; */
  width: 100%;
}
.projectsdivh2 {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.projectsdivh2 h2 {
  font-size: 3rem;
  color: rgb(226, 221, 221);
}
.headh3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #00ccff;
}
.headh32 {
  color: rgb(37 127 8);
}
.projectp span {
  color: #000;
  font-weight: 600;
  font-size: 1.1rem;
}
.projectp {
  color: rgb(67, 66, 66);
  font-weight: 900;
  font-size: 1.2rem;
}
