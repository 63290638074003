.quote {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100svh;
  /* background-color: #202124; */
  z-index: 9;
  position: absolute;
}
.quote2 {
  display: flex;
  justify-content: center;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote3 {
  border-radius: 20px;
  background: #383f4d;
  box-shadow: 9px 9px 18px #61b048, -9px -9px 18px #2d2e32;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding: 10px;
}
.quote3 Form {
  width: 100%;
}
.sucss {
  width: 400px;
}
.sucss2 h2 {
  font-size: 1.4rem;
  color: #fff;
}
.quoteconct {
  width: 100%;
  text-align: center;
}
.quoteconct h2 {
  font-size: 3rem;
  color: rgb(179, 173, 173);
}
.quote3 label input {
  font-size: 2rem !important;
}
.quote21 {
  border-radius: 15px;
  background: #383f4d;
  box-shadow: 7px 7px 13px #1e2129, -7px -7px 13px #525d71;
  padding: 50px;
}
.quote21 a {
  text-decoration: none;
  display: flex;
  gap: 20px;
  flex-direction: row;
  color: #fff;
}
@media screen and (max-width: 1156px) {
  .quote3 {
    width: 400px;
  }
}
@media screen and (max-width: 920px) {
  .quote3 {
    width: 400px;
  }
  .quote {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
}

@media screen and (max-width: 650px) {
  .quote {
    height: auto;
  }
}
