.resume {
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resume p {
  color: #fff;
}
