.AboutMainDiv {
  background-color: #383f4d;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container {
  position: relative;
}
.navbr {
  /* position: absolute; */
  /* z-index: 9999; */
  cursor: pointer;
  background-color: #383f4d;

  width: 100%;
}
