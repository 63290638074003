.float {
  position: fixed;
  /* width: 60px;
  height: 60px; */
  bottom: 0px;
  right: 0px;
  /* background-color: #25d366; */
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 100;
}
.lottie {
  width: 80px;
  cursor: pointer;
}
.lottie img {
  width: 80px;
  cursor: pointer;
}
.floatlinked {
  display: flex;
  flex-direction: column;
}
.floatlinked2 {
  color: #03bfef;
}
@media screen and (max-width: 768px) {
  .lottie img {
    width: 35px;
  }
}
